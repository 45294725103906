<template>
    <v-card>
        <v-toolbar dark color="primary">
            <v-toolbar-title>Customer Bill</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="formClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-row>
            <v-col cols="12" md="6">
                <v-simple-table>
                    <template v-slot:default>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td class="font-weight-bold">Customer ID</td>
                            <td>{{getApiData.details.hotelCustomerID}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Countery / State</td>
                            <td>{{getApiData.details.country}} / {{getApiData.details.state}}</td>
                        </tr> 
                        <tr>
                            <td class="font-weight-bold">Check In Date</td>
                            <td>{{getApiData.details.checkInDate}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Check Out Date</td>
                            <td>{{getApiData.details.checkOutDate}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Number Of Person</td>
                            <td>{{getApiData.details.numberOfPerson}}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12" md="6">
                <v-simple-table>
                    <template v-slot:default>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td class="font-weight-bold">Room</td>
                            <td>{{getApiData.details.room}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Contact Number</td>
                            <td>{{getApiData.details.contactNumber}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Amount To Pay</td>
                            <td>{{getApiData.details.amountToPay}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Due Amount</td>
                            <td>{{getApiData.details.dueAmount}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Paid Amount</td>
                            <td>{{getApiData.details.paidAmount}}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

        <hr/>

    </v-card>
</template>

<script>
    /*import { email, maxLength, required, sameAs } from "vuelidate/lib/validators";*/
    import axios from "axios";

    export default {
        name: "editAdminForm",
        props: ["hotelCustomerID", "pendingData"],
        watch: {
            dialog: {
                handler: function (val) {
                    console.log("watch", this.dialog, val);
                    if (this.dialog) {
                        this.$emit("formResponse", val);
                    }
                    this.dialog = true;
                },
                deep: true
            }
        },
        data() {
            return {
                e6: 1,
                step: 1,
                dialog: false,
                getApiData: [],
                snackbar: {
                    snackbar: false,
                    text: "Internal Server Error,Please Contact Admin",
                    timeout: 200000,
                    menu2: false,
                    save_button: false,
                    next_button: true,
                    success: false,
                    error: false,
                    number_of_second_form: 1,
                    HotelCustomerID: 1,
                    price: 0
                },
                formDatas: {},
                BillForm: {},
                rooms: [],
                RoomObject: {},
                countries: "",
                state: ""
            };
        },
        computed: {},
        mounted() {
            this.formData();
        },
        methods: {
            formClose(val) {
                console.log(val);
                this.$emit("formResponseClose", val);
            },

            async formData() {
                console.log(this.hotelCustomerID);
                const response = await axios.get(
                    "Hotel/GetCheckOutCustomerDetails/" + this.hotelCustomerID
                );
                this.getApiData = response.data;
                console.log(this.getApiData, response);
                const roomlist = await axios.get("Hotel/DDLRoomListAsync");
                this.rooms = roomlist.data;
            },
            getRoomPrice($event) {
                this.RoomObject.RoomIDs = $event;
                console.log("array:", this.RoomObject);
                axios
                    .post("Hotel/GetRoomFareAmount", this.RoomObject)
                    .then(response => {
                        this.snackbar.price = response.data;
                        console.log(this.snackbar.price);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Could not Fetch the price";
                    });
            },

            update() {
                let form = {
                    HotelCustomerID: this.getApiData.details.hotelCustomerID,
                    PaidAmount: parseFloat(this.getApiData.details.paidAmount),
                    PaymentType: this.getApiData.details.paymentType,
                    DueAmount: parseFloat(this.getApiData.details.dueAmount),
                    AmountToPay: parseFloat(this.getApiData.details.amountToPay),
                    IsCustomerCheckout: this.getApiData.details.isCustomerCheckOut
                };

                axios
                    .post("Hotel/CheckOut", form)
                    .then(response => {
                        console.log(response.data);
                        this.snackbar.success = response.data.success;
                        this.snackbar.next_button = false;
                        this.snackbar.save_button = true;
                        this.dialog = true;
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Internal Server Error, Please Contact Admin";
                    });
            },
            reset() {
                this.save_button = false;
                this.e6 = 1;
                this.$emit("FormClose", this.dialogue);
                this.snackbar.next_button = true;
            }
        }
    };
</script>

<style scoped>
</style>
