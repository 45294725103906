<template>
    <v-card>
        <v-snackbar v-model="snackbar.snackbar" color="success" :timeout="snackbar.timeout" top>
            {{snackbar.message}}
            <v-btn dark text @click="snackbar.snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t("checkout_information") }}</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn icon dark @click="formClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form class="fix-for-nepali-label">
                    <v-text-field v-model="hotelCustomerData.numberOfPerson"
                                  disabled>
                        <template v-slot:label>
                            {{ $t("no_of_person") }}
                        </template>
                    </v-text-field>
                    <v-menu 
                            :close-on-content-click="true"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            class="col-sm-6 col-md-4"
                            min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="hotelCustomerData.checkInDates"
                                          label="Check In Date"
                                          clearable
                                          prepend-icon="event"
                                          readonly
                                          v-on="on">
                                <template v-slot:label>
                                    {{ $t("check_in_date") }}
                                </template>
                            </v-text-field>

                        </template>
                        <v-date-picker v-model="hotelCustomerData.checkInDates"></v-date-picker>

                    </v-menu>
                    <v-menu 
                            :close-on-content-click="true"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="hotelCustomerData.checkOutDates"
                                          label=" check Out Date"
                                          clearable
                                          prepend-icon="event"
                                          readonly
                                          v-on="on">
                                <template v-slot:label>
                                    {{ $t("check_out_date") }}
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="hotelCustomerData.checkOutDates">

                        </v-date-picker>
                    </v-menu>

                    <v-text-field v-model="hotelCustomerData.amount"
                                  :error-messages="amountErrors"
                                  required>
                        <template v-slot:label>
                            {{ $t("amount") }}
                        </template>
                    </v-text-field>
                    <v-switch v-model="hotelCustomerData.customerOut">
                        <template v-slot:label>
                            {{ $t("is_customer_out") }}
                        </template>
                    </v-switch>

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">
                {{ $t("cancel") }}
            </v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    /*import { email, maxLength, required, sameAs } from "vuelidate/lib/validators";*/
    import axios from "axios";
    export default {
        name: "CustomerAdminFormForm",
        props: ["hotelCustomerData", "pendingData"],
        watch: {
            dialog: {
                handler: function (val) {
                    console.log("watch", this.dialog, val);
                    if (this.dialog == false) {
                        this.$emit("formResponse", val);
                    }
                    this.dialog = true;
                },
                deep: true
            }
        },
        data() {
            return {
                snackbar: {
                    snackbar: false,
                    timeout: 6000,
                    message: ""
                },
                infoData: {
                    dateMenu4: false,
                    dateMenu3: false,
                    dateMenu1: false,
                    dateMenu2: false,
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: "",
                },
                isCheckout: false,
                dialog: true,
                notifications: false,
                sound: true,
                widgets: false,
                userInfo: {},
                formData_2: {},
                rooms: [],
                BillForm: {},
                services: [],
                packages: [],
                RoomObject: {},
                countries: [],
                state: [],
                genderList: [],
                touristTypeList: [],
                ageGroup: [],
                selectedRoomData: [],
                validaty: [],
                storeRoomIDList: [],
                commaRoomIDList: "",
            };
        },
        mounted() {
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData);
                this.$emit("formResponse", this.servicesData);
            },
            changeCustomerFormCheckoutDate(index) {

                let data = {
                    "bookedFromDate": this.formData_2[index].checkInDateString,
                    "bookedToDate": this.formData_2[index].checkOutDateString,
                    "isDropDown": true,
                    "RoomIDList": this.commaRoomIDList,
                    "personInformationID": this.formData_2[index].personInformationID
                };
                console.log(data, this.formData_2[index])
                axios.post(
                    "Hotel/RoomStatusListAsync",
                    data
                ).then(response => {
                    this.selectedRoomData[index] = response.data;
                }).catch(err => {
                    console.log(err)
                })
            },
            getState(val) {

                axios.get('Shared/StateListByCountryCode?CountryCode=' + val).then(response => {
                    this.state = response.data
                });
            },
            formClose(val) {
                console.log(val)
                this.$emit("formResponseClose", val);
            },
            submit() {
                let param = {
                    
                    HomestayCustomerID: this.hotelCustomerData.homestayCustomerID,
                        Amount: parseInt(this.hotelCustomerData.amount),
                        CheckInDates: this.hotelCustomerData.checkInDates,
                        CheckOutDates: this.hotelCustomerData.checkOutDates,
                        CustomerOut: this.hotelCustomerData.customerOut,

                    };
                    axios
                        .post("Hotel/UpdateHomestayCustomerAsync", param)
                        .then((response) => {
                            if (response.data.success) {
                                this.updated = true;
                                this.hotelCustomerData = {};
                                this.servicesData.data = response.data;
                                this.servicesData.message = "Package Updated Successfully";
                                this.servicesData.color = "success";
                                this.dialogueClose();
                            }
                        })
                        .catch((response) => {
                            this.servicesData.data = response.data;
                            this.servicesData.message =
                                "Error Adding Service, Please Contact Admin";
                            this.servicesData.color = "error";
                            this.dialogueClose();
                        });
                
            },

            updateCustomerInformationData(index) {
                this.formData_2[index].checkInDate = this.formData_2[index].checkInDateString
                this.formData_2[index].checkOutDate = this.formData_2[index].checkOutDateString

                axios.post('Hotel/UpdateHotelPersonInformation', { person: this.formData_2[index] }).then(response => {
                    console.log(response)
                    if (response.data.success) {
                        this.snackbar.snackbar = true
                        this.snackbar.message = "Updated Customer Information"
                        this.$emit("formResponse", this.snackbar);

                    }
                }).catch(response => {
                    console.log(response.data.success)

                    this.snackbar.snackbar = true
                    this.snackbar.message = "Something Went wrong !!!"

                })

            },

            async formData() {

                axios.post(
                    "Hotel/GetHomestayListViewModelABC", {
                    //PersonIDs: this.hotelCustomerData.personIDs,
                        CustomerID: this.hotelCustomerData.homestayCustomerID
                    //RoomIDs: this.hotelCustomerData.roomIDs
                }).then(res => {
                    // this.selectedRoomData = res.data.roomList
                    this.formData_2 = res.data.personList;
                    res.data.personList.forEach((response, index) => {
                        if (response.country) {
                            this.getState(response.country)
                        }
                        this.selectedRoomData[index] = res.data.roomList
                        this.storeRoomIDList = res.data.roomList
                    })

                    let arr = []
                    this.storeRoomIDList.filter(response => {
                        arr.push(response.roomID)
                    })
                    this.commaRoomIDList = arr.join(",")

                })
            }
        }
    };
</script>

<style lang="scss" scoped>
    .title-top {
        background-color: #649eedbd !important;
        color: white !important;
    }
</style>
